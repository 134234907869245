@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&family=Montserrat:wght@400;500;700&display=swap');

.body {
  font-family: 'Montserrat';
  background-color: #255C4A;
}

.bg-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  left: 0;
  top: 0;
  position: fixed;
  pointer-events: none;
  background-image: url('./assets/Waterfall_StillImage.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card {
  width: 100%;
  height: 100%;
  padding: 4em 1.5em;
  background: linear-gradient(#ffffff 50%, #255C4A 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  transition: 0.5s;
}

.card:hover {
  background-position: 0 100%;
  color: white;
}

.card2 {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  transition: 0.5s;
  cursor: pointer;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), 70%, #255c4ab7);
}

.card2:hover {
  color: #A3C83A;
}

/* SIGNUP AND LOGIN STYLING */

form .txt-field {
  position: relative;
  border-bottom: 2px solid #2F6F50;
  margin: 30px 0;
}

.txt-field input{
  width: 100%;
  padding: 0 5px;
  height: 40px; 
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.txt-field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: .5s;
}

.txt-field input:focus ~ label{
  top: -10px;
  color: #2F6F50;
}

.txt-field input:not(:placeholder-shown) ~ label{
  top: -10px;
  color: #2F6F50;
}

.serviceButton {
  background-color: #2F6F50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
}